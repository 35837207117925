import Head from 'next/head';
import Error404, {
  BodyMessageOptions,
} from '@formbio/ui/components/Layout/404';

export { BodyMessageOptions };

export default function NotFound({
  bodyMessage = BodyMessageOptions.NotFound,
}: {
  bodyMessage?: BodyMessageOptions;
}) {
  return (
    <>
      <Head>
        <title>Page Not Found - Form Bio</title>
      </Head>
      <Error404 message={bodyMessage} />
    </>
  );
}
