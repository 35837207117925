import { Container, Stack, styled } from '@formbio/ui/components';
import { Typography } from '@formbio/ui/components/Typography';
import NextMuiLink from '@formbio/ui/components/Link/NextMuiLink';
import { Button } from '@formbio/ui/components/Button';

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  height: '100vh',
}));

const Styled404Stack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '.back-button': {
    marginTop: theme.spacing(2),
  },
  '.large-404': {
    color: theme.palette.primary[400],
    fontSize: '200px',
    // ~ matches line height with font size
    // to remove white space above text
    // and balance out the vertical centering
    lineHeight: '150px',
    paddingBottom: theme.spacing(3),
  },
}));

export enum BodyMessageOptions {
  NotFound = `The resource requested could not be found on this server.`,
  WorkflowRunNotFound = `Sorry, this workflow run does not exist or you do not have permission to access it.`,
  WorkflowNotFound = `Sorry, this workflow does not exist or you do not have permission to access it.`,
  Forbidden = `Sorry, you do not have access to this resource.`,
  JoinToday = `Sign Up Today `,
  FileNotFound = `Sorry, this file does not exist or you do not have permission to access it.`,
}

const JOIN_URL =
  'https://www.formbio.com/start?utm_campaign=gcpm-signup&utm_source=formbio&utm_medium=fb-signup';

export default function NotFound({
  message = BodyMessageOptions.NotFound,
}: {
  message?: BodyMessageOptions;
}) {
  return (
    <StyledContainer>
      <Styled404Stack>
        {/* special handling while creating an org is disabled */}
        {message === BodyMessageOptions.JoinToday ? (
          <Typography variant='body1'>
            <>
              {message}
              <a href={JOIN_URL} target='_blank' rel='noopener noreferrer'>
                {JOIN_URL}
              </a>
            </>
          </Typography>
        ) : (
          <>
            <Typography variant='h1' className='large-404'>
              404
            </Typography>
            <Typography variant='h2'>Page Not Found</Typography>
            <Typography variant='body1'>{message}</Typography>
          </>
        )}
        <div className='back-button'>
          <NextMuiLink nextHref='/' underline='none'>
            <Button variant='contained' color='primary'>
              Go to Homepage
            </Button>
          </NextMuiLink>
        </div>
      </Styled404Stack>
    </StyledContainer>
  );
}
